<template>
  <div class="area-product">
    <div id="swiper">
      <img
        src="@/assets/images/areaProduct/banner_products_area_afterschool.png"
        alt="区域课后服务云平台"
      />
      <div class="swiper_content">
        <h1>区域课后服务云平台</h1>
        <p>
          面向教育局、学校、机构、教师、学生、家长的一站式课后服务平台，统筹规划课后服务的资金监管、课程设计、评估量化问题
        </p>
      </div>
    </div>
    <!--  导航  -->
    <div class="navigation">
      <ul>
        <li
          v-for="(nav, index) in navList"
          :key="index"
          @click="goPage(index, nav.url)"
        >
          <span
            :class="{
              'active-nav': activeNav === index,
            }"
            >{{ nav.label }}</span
          >
        </li>
      </ul>
    </div>
    <!-- 导航内容  -->
    <div class="content">
      <div class="area">
        <div class="title">
          <h3>区域课后服务云平台</h3>
          <!-- <p class="title_en">
            District-school After-School Service Management Platform
          </p> -->
        </div>
        <div class="area_content">
          <p>
            <span>区域课后服务云平台</span>
            ，构建面向教育局、学校、机构、教师、学生、家长的一站式课后服务平台。解决课后服务的资金使用监管问题、解决课后服务优质均衡问题、解决课后服务课程单一问题、解决课后服务过程无法量化评估问题、为课后服务提供统筹规划支撑。
          </p>
          <div class="platform">
            <img
              src="@/assets/images/areaProduct/platform_afterschool_pc.png"
              alt=""
            />
            <p>
              从课程体系设计、资金安全管理、课时补贴发放、家校高效沟通、课程丰富程度、优质资源到班、课程质量评价等方面对政策落实情况进行实时、整体把控。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import platformBottomPc from '@/assets/images/areaProduct/platform_bottom_pc.png'
import platformBottomMobile from '@/assets/images/areaProduct/platform_bottom_mobile.png'

export default {
  name: 'School',
  components: {
    // swiper,
    // swiperSlide,
  },
  directives: {
    // swiper: directive,
  },
  data() {
    return {
      platformBottomPc,
      platformBottomMobile,
      navList: [
        { label: '区域课程管理平台', url: '/products-plans/area' },
        { label: '区域校园安全风险防控平台', url: '/products-plans/safety' },
        {
          label: '区域课后服务云平台',
          url: '/products-plans/afterschool',
        },
      ],
      activeNav: 2,
      swiperList: [
        {
          url: '图片路径',
          title: '图片描述',
        },
      ], // 轮播图片
      swiperOption: {
        // swiper操作项
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {
    goPage(index, url) {
      this.activeNav = index
      this.$router.push(url)
    },
  },
}
</script>

<style scoped lang="scss">
%area {
  #swiper {
    img {
      width: 100%;
    }
    position: relative;
    .swiper {
      @include px2vw('height', 750);
      img {
        width: 100%;
      }
      & > .swiper-pagination {
        width: auto !important;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0;
        ::v-deep span {
          padding: 0 20px;
          margin: 0 4px;
          border-radius: 4px;
        }
        ::v-deep .swiper-pagination-bullet-active {
          background-color: $--color-white;
        }
      }
    }
    .swiper_content {
      position: absolute;
      left: 50%;
      top: 38%;
      width: 100%;
      max-width: 1374px;
      transform: translate(-50%, -50%);
      text-align: center;
      color: $--color-white;
      z-index: 2;
      text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.5);
      h1 {
        @include px2vw('font-size', 56);
      }
      p {
        @include px2vw('font-size', 28);
      }
    }
  }
  .navigation {
    width: 100%;
    max-width: 1320px;
    margin: 0px auto;
    margin-bottom: 80px;
    padding-top: 38px;
    padding-bottom: 8px;
    @include px2vw('padding-left', 90);
    @include px2vw('padding-right', 90);
    box-sizing: border-box;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
    & ul {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        min-width: 120px;
        box-sizing: border-box;
        display: flex;
        margin: 0 6px;
        margin-bottom: 30px;
        cursor: pointer;
        span {
          padding: 6px 10px;
        }
        span.active-nav {
          background-color: $--color-primary;
          color: $--color-white;
        }
      }
      li::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: #999999;
        margin-left: 10px;
      }
      li:last-child()::after {
        content: '';
        display: none;
      }
    }
  }
  .content {
    background: #f7f7f8;
    // 通用头部样式
    .title {
      text-align: center;
      color: #333;
      h3 {
        line-height: 60px;
        font-size: 40px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    // 新高考
    .area {
      max-width: 1320px;
      margin: 0 auto;
      padding: 40px 0;
      &_content {
        & > div:nth-child(1) {
          width: calc(100% - 20px);
          max-width: 800px;
          text-align: center;
          padding: 36px 0;
          border: 1px solid #979797;
          font-size: 28px;
          margin: 60px auto;
        }
        & > p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          margin: 40px 60px;
          margin-bottom: 50px;
          text-align: justify;
          span {
            color: $--color-primary;
          }
        }
        .platform {
          display: flex;
          justify-content: space-around;
          align-items: center;
          background: $--color-white;
          padding: 60px 20px;
          margin-bottom: 30px;
          & > p {
            max-width: 744px;
            line-height: 32px;
            font-size: 18px;
            text-align: justify;
            color: #666;
            text-align: justify;
            span {
              color: $--color-primary;
            }
          }
          img {
            width: 30%;
          }
        }
      }
    }
    // 智慧校园
    .campus {
      margin: 0px auto;
      text-align: center;
      img {
        width: 90%;
      }
    }
  }
}
.view-pc {
  @extend %area;
  @media (min-width: 570px) and(max-width:1440px) {
    .navigation {
      max-width: 1100px;
      @include px2vw('padding-left', 50);
      @include px2vw('padding-right', 50);
    }
    .area {
      max-width: 1100px !important;
      padding: 0 !important;
      // &_content {
      //   div:nth-child(1) span {
      //     padding: 0 20px;
      //   }
      //   p {
      //     margin-top: 30px;
      //     font-size: 18px;
      //     margin-bottom: 0;
      //   }
      // }
    }
    .platform {
      img {
        margin-right: 25px;
      }
    }
    // .box {
    //   max-width: 1100px !important;

    // }
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %area;
  .area-product {
    margin-top: 44px;
    #swiper {
      .swiper {
        height: 200px;
        & > .swiper-pagination {
          bottom: 10px;
          ::v-deep span {
            padding: 0 10px;
            margin: 0 4px;
            border-radius: 4px;
          }
          ::v-deep .swiper-pagination-bullet-active {
            background-color: $--color-white;
          }
        }
      }
      .swiper_content {
        h1 {
          font-size: 22px;
        }
        p {
          font-size: 12px;
          margin: 0 20px;
          line-height: 20px;
        }
      }
    }
    .navigation {
      margin: 0 auto 40px;
      padding-left: 0;
      padding-right: 0;
      & ul {
        width: 100%;
        display: flex;
        li {
          min-width: unset;
          max-width: unset;
          margin: 0 0 10px;
          padding: 0 6px;
          text-align: center;
          border-right: 1px #999999 solid;
          &::after {
            margin-left: 0;
            background-color: $--color-white;
          }
          &:last-child {
            border-right: 0;
          }
          span {
            padding: 6px;
          }
        }
      }
    }
    .content {
      padding: 0 10px;
      box-sizing: border-box;
      .title_en {
        font-size: 14px !important;
        margin-top: 10px;
      }
      h3 {
        line-height: 30px !important;
      }
      .title {
        text-align: center;
        color: #333;
        h3 {
          line-height: 60px;
          font-size: 40px;
          font-weight: bold;
          margin: 0;
        }
        .title_en {
          font-size: 18px;
        }
        .title_en::after {
          content: '';
          display: block;
          width: 66px;
          height: 3px;
          margin: 20px auto;
          background-color: $--color-primary;
        }
      }
      .area {
        width: 90%;
        .area_content {
          div:nth-child(1) span {
            padding: 0 20px;
          }
          p {
            margin: 0;
            font-size: 18px;
            line-height: 32px;
            color: #666;
            text-align: justify;
          }
          .platform {
            display: block;
            padding: 20px;
            margin-top: 40px;
            img {
              width: 100%;
              margin-bottom: 20px;
            }
          }
        }
      }
      .campus {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
